import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import "../components/CSS/form.css"
import * as L from '../components/Solution/styled'
import * as S from '../components/Content/content'
import HelpForm from '../templates/helpForm'
import useTranslations from '../components/useTranslations';
import { LocaleContext } from './../components/Layout';

const HelpPage = props => {
  const post = props.data.markdownRemark;

  const { locale } = React.useContext(LocaleContext);
  const {
    retmonTitle,
    retmonDescription,
  } = useTranslations();

  const headerImage = locale === 'tr' ? 'https://res.cloudinary.com/konzek/image/upload/v1601885202/Retmon/web/help-form-tr-14.png'
    : 'https://res.cloudinary.com/konzek/image/upload/v1601885201/Retmon/web/help-form-eng-14-14.png'

  return (
    <>
      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={retmonDescription}
      />
      {/* <img className='help-header' src={headerImage} alt={post.frontmatter.formTitle} /> */}
      <L.Title>{post.frontmatter.title}</L.Title>
      <S.Content dangerouslySetInnerHTML={{ __html: post.html }} />
      <L.Title>{post.frontmatter.formTitle}</L.Title>
      <HelpForm />
    </>
  );
};

export const query = graphql`
  query Help($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        formTitle
        description
        page
      }
      html
    }
  }
`;

export default HelpPage;
