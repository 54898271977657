export const terminals = [
  { value: 'DCT195', label: 'DCT195 - Veri Toplama Terminali', color: '#00B8D9', },
  { value: 'DCT210-20', label: 'DCT210-20 - Veri Toplama Terminali', color: '#0052CC', }
];

export const sensors = [
  { value: 'WLS-02-1-Wire', label: 'WLS-02-1-Wire Su Kaçağı Sensörü', },
  { value: 'WLC-03', label: 'WLC-03 - Su Sızıntısı Algılama Kablosu', },
  { value: 'WLC-02', label: 'WLC-02 Su Kaçağı Probu (Yere Montaj)', },
  { value: 'VSK-01', label: 'VSK-01 1 Faz Kuru Kontak Voltaj Sensörü', },
  { value: 'TS-02', label: 'TS-02 Sıcaklık Sensörü - PT100 (-100/+200)', },
  { value: 'TS-01', label: 'TS-01 1-Wire Sıcaklık Sensörü', },
  { value: 'SS-03', label: 'SS-03 1-Wire Duman Sensörü', },
  { value: 'SS-01', label: 'SS-01 Duman Sensörü', },
  { value: 'SHC-01', label: 'SHC-01 Darbe Sensörü', },
  { value: 'MS-01', label: 'MS-01 Hareket Sensörü / PIR Dedektörü', },
  { value: 'LHT-01', label: 'LHT-01 1-Wire Işık Sensörü' },
  { value: 'IPCAM-01', label: 'IPCAM-01 IP Kamera' },
  { value: 'GNVS-01', label: 'GNVS-01 1-Wire Toprak Nötr Gerilim Sensörü' },
  { value: 'DS-01', label: 'DS-01 Kapı Sensörü' },
  { value: 'CHTS-02', label: 'CHTS-02 1-Wire Combo Sıcaklık + Nem Sensörü' },
  { value: 'ALS-01', label: 'ALS-01 Alarm Sireni Işık + Ses' },
  { value: 'ALB-01', label: 'ALB-01 Alarm Siren Butonu' },
  { value: 'AF-02', label: 'AF-02 Termal Efektli Hava Akış Sensörü' },
  { value: 'ACS-02', label: 'ACS-02 1-Wire Karbondioksit (Hava Kalite) Sensörü' },
  { value: '3GM-01', label: '3GM-01 3G USB Stick Modem' },
];

export const groupedOptions = [
  {
    label: 'Veri Toplama Terminalleri',
    options: terminals,
  },
  {
    label: 'Sensörler ve Ekipmanlar',
    options: sensors,
  }
];

export const type = [
  { value: 'bakim', label: 'Bakım ve Destek', name: 'Bakım ve Destek' },
  { value: 'garanti', label: 'Garanti',  name: 'Bakım ve Destek' },
  { value: 'lisans', label: 'Lisans', name: 'Bakım ve Destek'  },
  { value: 'sikayet', label: 'Şikayet/Görüş/Öneri', name: 'Bakım ve Destek'  }
];

export const optionLength = [
  { value: 1, label: 'general' },
  {
    value: 2,
    label:
      '',
  },
  {
    value: 3,
    label:
      "",
  },
];


// let bigOptions = [];
// for (let i = 0; i < 10000; i++) {
// 	bigOptions = bigOptions.concat(colourOptions);
// }

