import styled from 'styled-components'
import media from 'styled-media-query'
import Img from "gatsby-image"

export const Row = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-content: flex-start;
-ms-flex-line-pack: start;
align-content: flex-start;
-webkit-flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
-ms-flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
flex-wrap: ${props => props.wrap ? "wrap;" : "none"};
-webkit-box-pack:  ${props => props.justify || "center"};
-webkit-justify-content:  ${props => props.justify || "center"};
-ms-flex-pack:  ${props => props.justify || "center"};
justify-content: ${props => props.justify || "center"};
align-items: ${props => props.align || "center"};
`;

export const Column = styled.div`
width: ${props => props.width || "auto"};
margin: ${props => props.auto ? "auto" : "unset"};
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
text-align: ${props => props.text || "left"};
${media.lessThan('large')`
width: auto};
`}
`;

export const Header = styled.h1`
font-size: ${props => props.Size || "32px"};
`

export const SVG = styled.img`
width: ${props => props.Size || "auto"};
filter: ${props => props.gray ? "grayscale(1)" : "none"};
margin-right:${props => props.right || "0"};
margin:auto;
`
export const CardImages = styled.img`
&:hover{
  cursor: pointer;
  transform: scale3d(1.04, 1.04, 1.04);
  transition: transform .2s ease-in;
  } 
  ${media.lessThan('medium')`
margin-bottom: 2rem;
width: 21rem!important;
`}
`
export const Title = styled.h1`
font-size:${props => (props.size || 'var(--h1)')};
font-weight: var(--bold);
margin-bottom: 1rem;
&:after{
  border-left: 1.5em solid var(--red);
  border-left-color: var(--red);
  display: block;
  content: "";
  height: 0.2rem;
  margin-top: 0.2em;
}
`

export const SubTitle = styled.h2`
margin-top: ${props => props.high ? "3rem" : "0rem"};
font-weight: 400;
font-style: normal;
font-size: var(--infoText);
color:var(--info);
`;

export const Text = styled.p`
margin-top: ${props => props.high ? "3rem" : "0rem"};
font-weight: 400;
font-style: normal;
font-size: 16px;
`;

export const RetmonCTAButton = styled.button`
background: ${props => props.Color || "var(--red)"};
color:white;
border-radius: 2px;
padding:9px;
outline: none;
border: none;
margin-top: 5rem;
margin-bottom: 5rem;
cursor:pointer;
margin:${props => props.auto ? "auto" : "unset"}; 
width: ${props => props.width || "auto;"}; 
transition: all .3s ease; 
&:hover{
background: #ec0753;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: var(--redGR);
  } 
`
export const GatsbyImage = styled(Img)`
width:45rem;
margin:auto;
${media.lessThan('small')`
width: 34rem;
`}
`;
export const SolutionLogo = styled(Img)`
  width: 15rem;
  margin-bottom: 5px;
`
export const Margin = styled.div`
margin-top:7rem;
margin-bottom:5rem;
${media.lessThan('medium')`
margin: 0;
`}
`;