import React, { useState, useRef, Component } from 'react'
import { navigate } from 'gatsby'
import '../components/CSS/form.css'
import * as S from '../components/Form/styled'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'
// import Recaptcha from "react-google-recaptcha";
import { groupedOptions, formatGroupLabel, type } from "../data/products";
import Select from 'react-select'

const HelpFormPage = props => {

  const { retmonTitle, formlang } = useTranslations()

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const [validated, setValidated] = useState(false)
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]:e.target.value })
  }
  const handleChangeSelectService = values => {
    setState({ ...state, serviceType: values })
  }

  const handleChangeSelectProducts = values => {
    setState({ ...state, products: values })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': ' multipart/form-data' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      }).then(() => navigate(form.getAttribute('action')))
  }


  // const RECAPTCHA_KEY = "6LdMFrQZAAAAAOqEraWCxky1L0kPwZtfqxHk0dS9"
  //const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

  // const handleRecaptcha = e => {
  //   setState({ ...state, "g-recaptcha-response": e.target.value });
  // };

  // const recaptcha = useRef(null);

  const {
    name,
    company,
    mail,
    service_details,
    product,
    service
  } = useState({})

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  const RequiredField = (
    <span className="requiredField">* {formlang.requiredField}</span>
  )
  return (
    <>

      <form
        netlify
        validated={validated}
        onSubmit={handleSubmit}
        name="Service"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="Service" />
        <S.Label textAlign='left'>
          {formlang.companyInfos} {RequiredField}{' '}
        </S.Label>
        <S.FlexRow>
          <S.Input
            type="text"
            name="Şirket"
            value={company}
            placeholder={formlang.company}
            onChange={handleChange}
          ></S.Input>
        </S.FlexRow>
        <S.FlexRow>
          <S.Input
            type="text"
            required
            name="İsim Soyisim"
            value={name}
            placeholder={formlang.companyAdmin}
            onChange={handleChange}
          ></S.Input>
        </S.FlexRow>
        <S.FlexRow>
          <S.Input
            type="mail"
            required
            name="E-Posta"
            value={mail}
            placeholder={formlang.mail}
            onChange={handleChange}
          ></S.Input>
        </S.FlexRow>
        <S.Label textAlign='left'>
          {formlang.service_detail}
        </S.Label>
        <S.FormSelectWrapper>
          <S.TextArea
            name="Servis Açıklaması"
            value={service_details}
            spellcheck="false"
            onChange={handleChange}
            placeholder={formlang.service_detail_placeholder}
          ></S.TextArea>
        </S.FormSelectWrapper>
        <br />
        <Select
          isMulti
          placeholder={formlang.choose_product}
          name="product[]"
          value={product}
          onChange={handleChangeSelectProducts}
          closeMenuOnSelect={false}
          className="basic-multi-select"
          classNamePrefix="select"
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
        /> 
        <br />
       <Select
          name="service[]"
          value={service}
          onChange={handleChangeSelectService}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={formlang.choose_service}
          options={type}
          formatGroupLabel={formatGroupLabel}
        /> 
        <br />
        <S.RetmonCTAButton right width="12rem" type="submit">
          {validated === true ? <span>{formlang.sending}</span> : formlang.send}
        </S.RetmonCTAButton>
      </form>
    </>
  )
}


export default HelpFormPage
